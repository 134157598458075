@import 'scss/variables.scss';

.integral-rule-wrap {
    position: relative;
    height: 100%;
    background: url('./img/rule_background.png') bottom center no-repeat;
    background-size: cover;
    padding: 1.5*$rem;

    p {
        margin-bottom: 1.5*$rem;
        font-size: $rem*1.4;
        color: #5F350F;
        line-height: $rem*2.1;
    }
}