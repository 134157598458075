$dpr: 1;
$rem: 0.5rem/$dpr;
$gray:#666;
$deepGray:#333;
$lightGray:#999;
$white:#FFF;
$orange:#ff6913;
$green:#40c074;
$red:#ff7467;
$footer:#eeeeee;
$background:rgba(0, 0, 0, 0.8);

@mixin button($width, $height, $fontSize) {
    border-radius: $height/6;
    border-radius: $height/6;
    width: $width;
    height: $height;
    line-height: $height;
    font-size: $fontSize;
    box-sizing: content-box;
    text-align: center;

    &:active {
        opacity: 0.9;
    }
}

@mixin lightButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background-image: #FFF;
    border: 1px solid #40c074;
    color: #40c074;
    box-sizing: border-box;
}

@mixin deepButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background-image: linear-gradient(120deg, #7bc99a 0%, #40c074 100%);
    border: none 0px;
    color: white;

}

@mixin disableButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background: #ccc;
    border: none 0px;
    color: $lightGray;
}