@import 'scss/variables.scss';

.wrap {
    display: flex;
    align-items: center;
    margin-bottom: 1.5*$rem;
    padding: 0;

    .thumbnail {
        width: $rem*12;
        height: $rem*12;
        margin-right: $rem*1;
        position: relative;
        border-radius: 0.5*$rem;
        overflow: hidden;
        display: flex;
        align-content: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.8);

        img {
            max-height: 100%;
        }

        p {
            line-height: 1.2*$rem;
            font-size:1*$rem;
        }

        .departure {
            color: white;
            font-size: 1.2*$rem;
            position: absolute;
            bottom: 0* $rem;
            left: 0*$rem;
            padding: 0.5*$rem;
            width:100%;
            background: linear-gradient(0, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
        }
    }

    .right {
        flex: 1;
        line-height: 2*$rem;
        width: 10*$rem;
    }

    h4 {
        font-size: 1.6*$rem;
        color: $gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 2.2*$rem;
    }

    p {
        line-height: 2.2*$rem;
        overflow: hidden;

        span {
            margin-right: $rem;

            strong {
                color: $green;
            }
        }

        label {
            border: 1px solid $green;
            border-radius: 0.6*$rem;
            color: $green;
            margin-right: $rem;
            padding: 0 $rem;
            font-size: $rem;
        }

        &.date {
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .foot {
        font-size: 1.6*$rem;
        display: flex;
        align-items: center;
        margin-top: 0.5*$rem;

        span {
            color: $red;

            strong {
                font-size: 2*$rem;
                font-weight: normal;
            }
        }
    }
}