@import 'scss/variables.scss';

.detail {
    background: white;
    padding: $rem;

    h1 {
        margin: 0;
        font-size: 2*$rem;
        line-height: 3*$rem;
        margin-bottom:$rem;
    }

    pre {
        white-space: pre-wrap;

        p {
            line-height: 2.4*$rem;
        }
    }


}

.list {
    background: white;

    .line {
        margin: 0 $rem;
        padding: $rem 0;
        display: flex;
        border-bottom: 1px dashed #eee;
        min-height: 5*$rem;
        justify-content: space-between;
        align-items: center;

        .right {
            color: $gray;
            font-size: 1.2*$rem;
        }

        .left {
            color: $deepGray;
            font-size: 1.4*$rem;
        }

    }
}