@charset "utf-8"; //// Remove default margin and padding.
@import './variables.scss';

blockquote,
button,
dd,
div,
dl,
dt,
fieldset,
form,
hr,
input,
legend,
li,
ol,
p,
pre,
span,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  line-height: 100%;
}

html {
  font-size: 20px;
  width: 100%;
  color: #666;
  height: 100%;
}

body {
  font-family: "PingFang SC", "HanHei SC", "microsoft yahei", Arial !important;
  //PingFang SC, Verdana, Helvetica Neue, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
  font-weight: normal;
  font-size: 2.8 * $rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  line-height: 100%;
}

ol,
ul {
  list-style: none;
}

fieldset,
img {
  vertical-align: middle;
  border: 0 none;
}

img {
  max-width: 100%;
}

table,
td,
th,
tr {
  margin: 0;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  outline: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  appearance: none;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

textarea {
  resize: none;
}

input {
  line-height: normal;
}

::-webkit-input-placeholder {
  color: #999;
}

a,
button,
div,
i,
input,
li,
textarea {
  border: none;
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
img {
  -webkit-touch-callout: none;
}

a,
a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}

select {
  appearance: none;
}

em,
i {
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

