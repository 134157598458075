@import 'scss/variables.scss';

.wrap {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5*$rem;

    .thumbnail {
        width: $rem*4;
        height: $rem*4;
        margin-right: $rem*1;
        position: relative;
        border-radius: 2*$rem;
        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .right {
        flex: 1;
        line-height: 2*$rem;
    }

    h4 {
        font-size: 1.6*$rem;
        color: $gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 2.2*$rem;
    }

    p {
        line-height: 2.2*$rem;

        i {
            font-size: 1.2*$rem;
            color: $red;
        }
    }

    .imageBox {
        overflow-y: scroll;
        white-space: nowrap;
        img {
            height:$rem*6;
            margin-right: $rem;
        }
    }
}