@import './reset.scss';
@import './variables.scss';
@import './font/iconfont.scss';

html {
    // overflow: hidden;
}

[disabled] {
    // background: #ddd !important;
    // border-color: #ddd;
    // color: $lightGray !important;
    filter: grayscale(100%);
}

body,
#root {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: none;
    font-size: 1.4*$rem;

}

button[disabled] {
    -webkit-filter: grayscale(100%);
}

pre {
    font-family: "PingFang SC",
        "HanHei SC",
        "microsoft yahei",
        Arial !important;
}

